export default {
  400: "参数异常",
  500: "未知",
  1001: "用户名/密码不正确",
  1002: "登录参数不完整",
  1003: "您还未登录！",
  1004: "参数不完整",
  1005: "班级满员",
  1006: "已加入该班级",
  1007: "加入班级失败",
  1008: "添加排课计划失败",
  1009: "班级已经关联导师",
  1010: "排课已达上限",
  1011: "解散班级失败",
  1012: "班级没有关联老师",
  1013: "该周的时间无法满足排课计划",
  1014: "班级状态异常",
  1015: "没有找到对应的周计划",
  1016: "没有找到对应的班级信息",
  1017: "周计划时间不合法，结束时间在开始时间之前",
  1018: "微信登录失败",
  1019: "无效用户",
  1020: "无效的邀请码",
  1021: "邀请码绑定失败",
}