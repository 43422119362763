import http from "./http";
import errorCode from "./errorCode";
import dayjs from "dayjs";
import Vue from "vue";
import { getUserInfo } from "@/api/common";

// for local programming
// let host = process.env.NODE_ENV === "development" ? "https://dev.offer.sibaiji.cn" : "https://offer.sibaiji.cn";
// // for webview url request
// if (window.location.href.includes('dev.')) {
//   host = "https://dev.offer.sibaiji.cn";
// }
console.log(process.env.NODE_ENV);
let host = process.env.NODE_ENV === "development" ? "/offerApi" : "/offerApi";
// for webview url request
if (window.location.href.includes("dev.")) {
  host = "/offerApi";
}
host = "https://ap.sqjoy.com/offerApi";
function formatDate(time, format = "YYYY-MM-DD", unix) {
  if (unix) {
    return dayjs.unix(time).format(format);
  }
  return dayjs(time).format(format);
}

function businessErrorGuard(data, errorMessageMapping = errorCode) {
  let { code, message } = data;
  if (code !== 200) {
    (console.error || console.data).bind(console)("业务错误:", data);
    message = errorMessageMapping[code] || message;
    throw message;
  }
}

// 课表 done
export async function getCalendarAll(params) {
  if (!Vue.prototype.$linkId) {
    let res = await getUserInfo();
    if (res && res.data && res.data.linkId) {
      Vue.prototype.$linkId = res.data.linkId;
      // Vue.prototype.$linkId = 111;
    }
  }
  let { data } = await http.get(`${host}/offer/plan/query`, {
    params: {
      ...params,
      teacherId: Vue.prototype.$linkId,
    },
  });

  businessErrorGuard(data);

  function format(res) {
    if (!Array.isArray(res)) return [];
    res = res.map((v) => {
      return {
        ...v,
        date: formatDate(v.timestamp, "MM-DD"),
        day_str: ["日", "一", "二", "三", "四", "五", "六"][
          dayjs(v.timestamp).day()
        ],
      };
    });
    return res;
  }
  const { serverTime } = data.attrMaps;
  return {
    list: format(data.data),
    today: formatDate(serverTime, "MM-DD"),
    serverTime,
  };
}

// 课时记录
export async function getHistoryList(config) {
  let params = {
    ...config,
    startTime: config.startTime
      ? +dayjs(config.startTime).format("YYYYMMDD")
      : 0,
    endTime: config.endTime ? +dayjs(config.endTime).format("YYYYMMDD") : 0,
    teacherId: Vue.prototype.$linkId,
  };
  let { data } = await http.get(`${host}/offer/courseRecord/queryMyPlan`, {
    params,
  });

  businessErrorGuard(data);
  const res = data.data || {};
  function format(res) {
    if (!Array.isArray(res)) return [];
    res = res.map((v) => {
      return {
        ...v,
      };
    });
    return res;
  }
  return {
    list: format(res.list),
    total: res.total,
  };
}

// 确认上课
export async function confirmClass(params) {
  let { data } = await http.post(
    `${host}/offer/courseRecord/confirm`,
    {
      ...params,
      teacherId: Vue.prototype.$linkId,
    },
    { json: true }
  );

  businessErrorGuard(data);
  return true;
}

// 我的班级  我的课程
export async function getGroupAll(params) {
  let { data } = await http.get(`${host}/offer/group/query`, {
    params: {
      ...params,
      teacherId: Vue.prototype.$linkId,
    },
  });
  businessErrorGuard(data);
  return data.data || [];
}

export async function getTimeQuery(params) {
  let { data } = await http.get(`${host}/offer/teacherTime/query`, {
    params: {
      ...params,
      teacherId: Vue.prototype.$linkId,
    },
  });
  businessErrorGuard(data);
  return data.data || [];
}

export async function postTimeSet(params) {
  let { data } = await http.post(
    `${host}/offer/teacherTime/save`,
    {
      ...params,
      teacherId: Vue.prototype.$linkId,
    },
    { json: true }
  );

  businessErrorGuard(data);
  return true;
}

// todo ---新增API
export async function getFreeTimeQuery() {
  let id = Vue.prototype.$linkId;

  let { data } = await http.get(`${host}/offer/teacherTime/${Number(id)}/${2}`);
  businessErrorGuard(data);
  return data.data || [];
}

export async function addFreeTime(params) {
  let { data } = await http.post(
    `${host}/offer/teacherTime/addFreeTime`,
    {
      ...params,
    },
    { json: true }
  );

  businessErrorGuard(data);
  return true;
}
export async function editFreeTime(params) {
  let { data } = await http.post(
    `${host}/offer/teacherTime/edit`,
    {
      ...params,
    },
    { json: true }
  );

  businessErrorGuard(data);
  return true;
}
// 课时详情
export async function plan(id) {
  let { data } = await http.get(`${host}/offer/plan/${id}`);

  businessErrorGuard(data);
  return data.data || [];
}
// 消息通知
export async function newMessage() {
  let { data } = await http.get(`${host}/offer/message/new`);

  businessErrorGuard(data);
  return data.data || [];
}
// 消息列表
export async function messageList(params) {
  let { data } = await http.get(
    `${host}/offer/message/record?pageNum=${params.pageNum}&pageSize=${params.pageSize}`
  );

  businessErrorGuard(data);
  return data.data || [];
}
// 审核详情
export async function modifyRecordDetail(id) {
  let { data } = await http.get(`${host}/offer/modify/record/detail/${id}`);

  businessErrorGuard(data);
  return data.data || [];
}
// 请假或调课详情
export async function modifyDetail(id) {
  let { data } = await http.get(`${host}/offer/modify/detail/${id}`);

  businessErrorGuard(data);
  return data.data || [];
}
// 请假或调课申请空闲时间接口
export async function modifyFreeTime(id) {
  let { data } = await http.get(`${host}/offer/modify/freeTime/${id}`);

  businessErrorGuard(data);
  return data.data || [];
}
// 撤回申请
export async function modifyRecall(id) {
  let { data } = await http.post(`${host}/offer/modify/recall/${id}`);

  businessErrorGuard(data);
  return true;
}
// 重新提交
export async function modifyReSubmit(params) {
  let { data } = await http.post(
    `${host}/offer/modify/reSubmit`,
    {
      ...params,
    },
    { json: true }
  );

  businessErrorGuard(data);
  return true;
}
export async function modifyReSubmit2(params) {
  let { data } = await http.post(
    `${host}/offer/modify/reSubmit2`,
    {
      ...params,
    },
    { json: true }
  );

  businessErrorGuard(data);
  return true;
}
// 提交申请
export async function modifyFiling(params) {
  let { data } = await http.post(
    `${host}/offer/modify/filing`,
    {
      ...params,
    },
    { json: true }
  );

  businessErrorGuard(data);
  return true;
}
// 编辑空闲时间
export async function freeTimeEdit(params) {
  let { data } = await http.post(
    `${host}/offer/teacherTime/editFreeTime`,
    {
      ...params,
    },
    { json: true }
  );

  businessErrorGuard(data);
  return true;
}
// 删除空闲时间
export async function freeTimeDel(params) {
  let { data } = await http.post(
    `${host}/offer/teacherTime/del`,
    {
      ...params,
    },
    { json: true }
  );

  businessErrorGuard(data);
  return true;
}
// 审核信息列表
export async function modifyPage(params) {
  let { data } = await http.get(
    `${host}/offer/modify/page?pageNum=${params.pageNum}&pageSize=${params.pageSize}`
  );

  businessErrorGuard(data);
  return data.data || [];
}
// 课时记录数量
export async function courseRecordNumber(config) {
  let params = {
    startTime: config.startTime
      ? +dayjs(config.startTime).format("YYYYMMDD")
      : 0,
    endTime: config.endTime ? +dayjs(config.endTime).format("YYYYMMDD") : 0,
    teacherId: Vue.prototype.$linkId,
  };
  let data = await http.get(`${host}/offer/courseRecord/number`, { params });

  // businessErrorGuard(data);
  return data.data || [];
}
// ---
